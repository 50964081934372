import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { useDispatch } from 'react-redux'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Circle,
  Flex,
  VStack,
} from '@chakra-ui/react'

import { DeviceType } from '@/graphql/generated/schemas'
import { openDeviceDrawer, setDevice } from '@/redux/ui/uiSlice'

import { FacilityGroupsI } from '../types/types'
import { DeviceListItem } from './DeviceListItem'
import { DeviceListItemSkeleton } from './DeviceListItemSkeleton'

interface IProps {
  loading: boolean
  facilityGroups: FacilityGroupsI
  openRemoveDevice?: (deviceId: string, type: DeviceType) => void
  openEditDevice?: (deviceId: string, type: string) => void
}

export const DevicesTable = ({
  loading,
  facilityGroups,
  openRemoveDevice,
  openEditDevice,
}: IProps) => {
  const dispatch = useDispatch()

  const handleEditDevice = (deviceId: string, type: string) => {
    openEditDevice(deviceId, type)
  }

  return (
    <Box h='calc(100vh - 137px)' overflow='scroll' p='6'>
      <Box m='auto' maxW='1100px'>
        <VStack align='stretch' spacing='6'>
          {loading || !facilityGroups ? (
            <>
              <DeviceListItemSkeleton />
              <DeviceListItemSkeleton />
              <DeviceListItemSkeleton />
            </>
          ) : facilityGroups.length > 0 ? (
            <Accordion allowMultiple allowToggle defaultIndex={[0]}>
              <VStack align='stretch' spacing='6'>
                {facilityGroups.map(({ facilityName, devices }) => (
                  <AccordionItem
                    _hover={{
                      boxShadow: '0px 2px 5px 6px rgba(0, 0, 0, 0.15)',
                    }}
                    border='none'
                    borderRadius='5px'
                    boxShadow='0px 2px 6px 2px rgba(0, 0, 0, 0.1)'
                    key={facilityName}
                    overflow='hidden'
                    transition='ease-in-out'
                    transitionDuration='300ms'
                    transitionProperty='all'
                  >
                    <AccordionButton
                      _focus={{ boxShadow: 'none' }}
                      _hover={{
                        bgColor: 'whitesmoke',
                      }}
                      bg='white'
                      p='4'
                    >
                      <Box paddingRight='3'>
                        <HiOutlineOfficeBuilding color='#000' size={24} />
                      </Box>
                      <Box
                        data-testid='devicesPage_table_facilityName'
                        flex='1'
                        fontSize='20px'
                        fontWeight='extrabold'
                        letterSpacing='-0.6px'
                        textAlign='left'
                      >
                        {facilityName}
                      </Box>
                      <Flex alignItems='center'>
                        <Circle
                          bg='#14327A'
                          color='white'
                          data-testid='devicesPage_table_deviceNumber'
                          fontSize='14px'
                          fontWeight='bold'
                          h='24px'
                          minW='24px'
                          mr='3'
                          p='2'
                          w='auto'
                        >
                          {devices.length}
                        </Circle>
                        <AccordionIcon />
                      </Flex>
                    </AccordionButton>
                    {devices.map((d) => (
                      <AccordionPanel key={d?.id} px='4' py='3'>
                        <DeviceListItem
                          deviceHealth={d?.status}
                          deviceType={d?.type as DeviceType}
                          facility={d?.facility?.shortName}
                          floor={d?.floor?.name}
                          handleDeviceClick={() => {
                            dispatch(setDevice({ id: d?.id, type: d?.type }))
                            dispatch(openDeviceDrawer())
                          }}
                          id={d?.id}
                          openEditDevice={() =>
                            handleEditDevice(d?.id, d?.type)
                          }
                          openRemoveDevice={() =>
                            openRemoveDevice(d?.id, d?.type)
                          }
                          title={d?.name}
                        />
                      </AccordionPanel>
                    ))}
                  </AccordionItem>
                ))}
              </VStack>
            </Accordion>
          ) : (
            <Box textAlign='center'>List is empty</Box>
          )}
        </VStack>
      </Box>
    </Box>
  )
}
