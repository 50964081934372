import { usePermissions } from '@/hooks/usePermissions'
import { useSelector } from 'react-redux'

import { Box, Circle, Flex, Grid, Icon } from '@chakra-ui/react'

import {
  AccessControl,
  Alarm,
  Camera,
  Facility,
  Floor,
} from '@/components/icons'
import { Card, EditRemoveButton } from '@/components/ui'
import { DeviceStatus, DeviceType } from '@/graphql/generated/schemas'
import { selectEditMode } from '@/redux/ui/uiSlice'
import { getDeviceStatusColor } from '@/utils/devices'

interface IProps {
  id: string
  title: string
  facility: string
  floor: string
  deviceHealth: DeviceStatus
  deviceType: DeviceType
  handleDeviceClick: () => void
  openRemoveDevice?: (deviceId: string) => void
  openEditDevice?: (deviceId: string) => void
}

export const DeviceListItem = ({
  id,
  title,
  facility,
  floor,
  deviceHealth,
  deviceType,
  handleDeviceClick,
  openRemoveDevice,
  openEditDevice,
}: IProps) => {
  const isEditMode = useSelector(selectEditMode)
  const { shouldEnableDeleteDevice } = usePermissions()
  return (
    <Grid gridTemplateColumns='1fr auto'>
      <Card
        _hover={{
          boxShadow: '0px 2px 5px 6px rgba(0, 0, 0, 0.15)',
        }}
        p='0'
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='all'
      >
        <Grid templateColumns='1fr min-content'>
          <Flex
            alignItems='center'
            borderRight='1px solid #D5DCE4'
            flexDirection='column'
            justifyContent='center'
          >
            <Grid
              _hover={{
                bgColor: 'whitesmoke',
              }}
              alignItems='center'
              cursor='pointer'
              gap='2'
              h='calc(100% - 10px)'
              onClick={handleDeviceClick}
              p='4'
              rounded='4px'
              templateColumns='45px 1fr'
              transition='ease-in-out'
              transitionDuration='300ms'
              transitionProperty='background-color'
              w='calc(100% - 10px)'
            >
              <Circle bg={getDeviceStatusColor(deviceHealth)} size='45px'>
                {deviceType === DeviceType.Camera ? (
                  <Icon as={Camera} boxSize='20px' color='#fff' />
                ) : deviceType === DeviceType.Door ? (
                  <Icon as={AccessControl} boxSize='20px' color='#fff' />
                ) : (
                  <Icon as={Alarm} boxSize='20px' color='#fff' />
                )}
              </Circle>
              <Box
                color='#353849'
                data-testid='devicesPage_tableItem_deviceName'
                fontSize='20px'
                fontWeight='bold'
                letterSpacing='-0.67px'
                lineHeight='25px'
              >
                {title}
              </Box>
            </Grid>
          </Flex>

          <Grid templateColumns='1fr 1fr' w='full'>
            <Flex
              alignItems='center'
              borderRight='1px solid #D5DCE4'
              flexDirection='column'
              justifyContent='center'
              minWidth='150'
              p='4'
            >
              <Box
                color='#353849'
                data-testid='DeviceListItem:facilityShortName'
                fontSize='16px'
                fontWeight='bold'
                letterSpacing='-0.67px'
                lineHeight='20px'
              >
                {facility}
              </Box>
              <Box
                color='#2D2E41'
                fontSize='12px'
                fontWeight='medium'
                letterSpacing='-0.4px'
                lineHeight='15px'
                mb='2'
                mt='1'
                opacity='0.5'
              >
                Facility
              </Box>
              <Icon as={Facility} boxSize='22px' strokeColor='#000' />
            </Flex>
            <Flex
              alignItems='center'
              flexDirection='column'
              justifyContent='center'
              minWidth='150'
              p='4'
            >
              <Box
                color='#353849'
                data-testid='DeviceListItem:floorName'
                fontSize='16px'
                fontWeight='bold'
                letterSpacing='-0.67px'
                lineHeight='20px'
              >
                {floor}
              </Box>
              <Box
                color='#2D2E41'
                fontSize='12px'
                fontWeight='medium'
                letterSpacing='-0.4px'
                lineHeight='15px'
                mb='2'
                mt='1'
                opacity='0.5'
              >
                Floor
              </Box>
              <Icon as={Floor} boxSize='22px' strokeColor='#000' />
            </Flex>
          </Grid>
        </Grid>
      </Card>
      {isEditMode && (
        <EditRemoveButton
          handleOnDelete={() => openRemoveDevice(id)}
          handleOnEdit={() => openEditDevice(id)}
          showDelete={shouldEnableDeleteDevice}
        />
      )}
    </Grid>
  )
}
